import { render, staticRenderFns } from "./AdminManage.vue?vue&type=template&id=584c92ca&scoped=true"
import script from "./AdminManage.vue?vue&type=script&lang=js"
export * from "./AdminManage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "584c92ca",
  null
  
)

export default component.exports